import {Message, CarPlate} from './data';
import React, {useEffect, useState} from "react";
import Clock from "../components/Clock";

const Screen = () => {

    let [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % Message.length);
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="wrap">
            <div className="text-logo">
                <h1><img src={process.env.PUBLIC_URL + "/gslogo.png"} alt="GS Logo"/>GS Logo</h1>
                <h2><span>에너지플러스</span> <span style={{color: '#009999'}}>허브</span> 내곡 </h2>
            </div>
            <strong>{CarPlate}</strong>
            <p style={{whiteSpace: 'pre-line'}}>
                {Message[index].replace(/<br\s*\/?>/gi, '\n')}
            </p>
            <Clock/>
        </div>
    )

};
export default Screen;
