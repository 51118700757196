import React, {useEffect, useRef} from 'react';
import Clock from "../components/Clock";
import Screen from "../screen/Screen";

const VideoGS = ({ onVideoComplete }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {

            if (videoRef.current && videoRef.current.currentTime === videoRef.current.duration) {
                onVideoComplete();
            }
        },  500);

        return () => {
            clearInterval(interval);
        };
    }, [onVideoComplete]);

    return (
        <div className="wrap" >
            <div className="text-logo">
                <h1><img src="/gslogo.png" alt="GS Logo"/>GS Logo</h1>
                <h2><span>에너지플러스</span> 허브 내곡 </h2>
            </div>
            <video ref={videoRef} width="100%" height="100%" autoPlay muted className="video-area">
                <source src="/videoGS.mp4" type="video/mp4"/>
            </video>
            <Clock/>
        </div>
    );
};

export default VideoGS;