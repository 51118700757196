import React, {useEffect, useState} from 'react';

const Clock = () => {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const timerID = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => {
            clearInterval(timerID)
        }
    }, []);

    return (
        <div className='clock'>
            <span>{currentTime.toLocaleTimeString()}</span>
        </div>
    );
};

export default Clock;
