const Message =
    [
        '환영 합니다.',
        '세차 고객님<br/>환영 합니다.',
        '키오스크<br/>옆으로 천천히<br/>진입하세요.',
        '세차요금<br/>셀프 결제 해주세요.',
    ]

const CarPlate = '170루 9213';


export {Message, CarPlate}