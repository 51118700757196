
import './App.css';
import React, {useState} from "react";
import Screen from "./features/screen/Screen";
import VideoGS from "./features/screenforNoReg/VideoGS";

function App() {
    const [videoComplete, setVideoComplete] = useState(false);

    const handleVideoComplete = () => {
        setVideoComplete(true);
    };

    return (
        <main className="container">
            {!videoComplete && <VideoGS onVideoComplete={handleVideoComplete}/>}
            {videoComplete && <Screen />}
        </main>
    );
}

export default App;
